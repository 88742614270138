import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PersonalSteps from './personal-steps';
import BusinessLtdSteps from './business-ltd-steps';
import BusinessSoleSteps from './business-sole-steps';
import BusinessPartnerSteps from './business-partner-steps';

import { isGaTrackingAllowed } from '../../helpers/analyticsHelper';
import { ProposalTypes } from '../../enums/ProposalTypes';
import { LeasingDealTypes } from '../../enums/LeasingDealTypes';
import { GetThankYouPageUrl } from '../../helpers/urlHelper';
import { ProposalDataHelper } from './helpers/ProposalDataHelper';
import { API_IsInternal } from '../../environment/integration/api/AuthenticationAPI';
import { API_CompleteEnquiry, API_GetEnquiryId, API_UpdateEnquiry } from '../../environment/integration/api/ProposalEnquiriesAPI';

interface IProps {
    isInternal: boolean;
    proposalType: ProposalTypes;
    dispatchGaTracking: any;
    dispatchInternalCheck: any;
    handleFormReset: any;
    handleFormUpdate: any;
    handleFormStep: any;
    formData: any;
    stepIndicator: number;
}

export const mapStateToProps = (state) => {
    return {
        isInternal: state.isInternal,
        formData: state.formData,
        stepIndicator: state.stepIndicator
    };
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchGaTracking: (tracking: boolean) => {
            dispatch({ type: "UPDATE_GA_TRACKING", tracking: tracking });
        },
        dispatchInternalCheck: (internal: boolean) => {
            dispatch({ type: "UPDATE_INTERNAL", internal: internal });
        },
        handleFormStep: (step) => {
            dispatch({ type: 'UPDATE_INDICATOR', step: step });
        },
        handleFormUpdate: (step, data) => {
            dispatch({ type: 'UPDATE_FIELDS', step: step, data: data });
        },
        handleFormReset: () => {
            dispatch({ type: 'RESET_FIELDS' });
            dispatch({ type: 'UPDATE_INDICATOR', step: 1 });
        }
    }
}

//TODO: required validation allows empty strings, fix using custom validator?
const ProposalWizard: React.FC<IProps> = ({ isInternal, proposalType, dispatchGaTracking, dispatchInternalCheck, handleFormReset, handleFormUpdate, handleFormStep, formData, stepIndicator }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(window.location.search);
    const [proposalEnquiryId, setProposalEnquiryId] = useState(null);
    const [progressReferer, setProgressReferer] = useState<number>(0);
    const [userAgent, setUserAgent] = useState(null);
    let quoteid = queryParams.get("quoteid");
    let enquiryid = queryParams.get("enquiryid");
    let uid = queryParams.get("uid");
    let directfinance = queryParams.get("directfinance");
    let tenantId = queryParams.get("tenantId") || "ED5C769D-E275-4352-B228-9D9277D91A2F";
    let origin = queryParams.get("origin") || "https://www.leasingoptions.co.uk";

    useEffect(() => {
        API_GetEnquiryId(proposalType, quoteid, enquiryid, uid, directfinance === "1", origin)
            .then(result => {
                if (result.data.data) {
                    setProposalEnquiryId(result.data.data.id);
                }
            });
    }, [proposalType, location]);

    useEffect(() => {
        handleFormReset();
    }, [proposalType]);

    useEffect(() => {
        const checkInternalIP = () => {
            if (isInternal === null) {
                API_IsInternal()
                    .then(response => {
                        dispatchInternalCheck(response?.data);
                    });
            }
        }

        // Set user aagent string
        setUserAgent(window.navigator.userAgent);

        // Check for internal user on first pageload
        checkInternalIP();

        // Check GA tracking
        dispatchGaTracking(isGaTrackingAllowed(tenantId));
    }, []);

    const handleProgressReferer = (step: number) => setProgressReferer(step);

    const handleMoveBack = (step: number) => {
        handleFormStep(step - 1);
    }

    const handleMoveForward = (step: number, data) => {
        const proposalData = ProposalDataHelper.getProposalData(proposalType, {
            ...formData,
            [step]: data
        });

        handleFormUpdate(step, data);

        API_UpdateEnquiry(proposalEnquiryId, proposalData);

        // Check if the request has come from the step indicator and has a referer step ID
        if (progressReferer > 0) {
            handleFormStep(progressReferer);
            setProgressReferer(0);
        } else {
            handleFormStep(step + 1);
        }
    }

    const handleSubmit = (step: number, data) => {
        const proposalData = ProposalDataHelper.getProposalData(proposalType, {
            ...formData,
            [step]: data
        });

        handleFormUpdate(step, data);

        API_CompleteEnquiry(proposalEnquiryId, proposalData, userAgent)
            .then(() => {
                handleFormReset();
                window.location.replace(origin + (isInternal ? GetThankYouPageUrl(true) : GetThankYouPageUrl(false, enquiryid, (proposalType === ProposalTypes.Personal ? LeasingDealTypes.Personal : LeasingDealTypes.Business), "proposal")));
            });
    }

    //stepIndicator check on this level prevents re-rendering of all components on step change
    const renderProposalSteps = (proposalType: ProposalTypes) => {
        let steps = null;

        
        switch (proposalType) {
            case ProposalTypes.Personal:
                steps = <PersonalSteps step={stepIndicator} formData={formData} onBack={handleMoveBack} onForward={handleMoveForward} onStepChange={handleFormStep} onProgressRefererChange={handleProgressReferer} onSubmit={handleSubmit} />
                break;

            case ProposalTypes.BusinessLtd:
                steps = <BusinessLtdSteps step={stepIndicator} formData={formData} onBack={handleMoveBack} onForward={handleMoveForward} onStepChange={handleFormStep} onProgressRefererChange={handleProgressReferer} onSubmit={handleSubmit} />
                break;

            case ProposalTypes.BusinessSole:
                steps = <BusinessSoleSteps step={stepIndicator} formData={formData} onBack={handleMoveBack} onForward={handleMoveForward} onStepChange={handleFormStep} onProgressRefererChange={handleProgressReferer} onSubmit={handleSubmit} />
                break;

            case ProposalTypes.BusinessPartner:
                steps = <BusinessPartnerSteps step={stepIndicator} formData={formData} onBack={handleMoveBack} onForward={handleMoveForward} onStepChange={handleFormStep} onProgressRefererChange={handleProgressReferer} onSubmit={handleSubmit} />
                break;
        }

        return steps;
    }

    return renderProposalSteps(proposalType);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProposalWizard);