import { AppConfig } from '../interfaces/AppConfig.interface';

export let fcdConfig: AppConfig = {
    defaults: {
        companyName: "Family Car Deals",
    },
    theme: {
        images: {
            logoPath: "/affiliate/images/logos/fcd.svg",
            faviconPath: "/favicons/fcd"
        },
        colors: {
            primaryBg: "#7c03c7",
            primaryBgHover: "#6903a9",
            primaryText: "#ffffff",
            secondaryBg: "#ff4436",
            secondaryBgHover: "#e63e31",
            secondaryText: "#ffffff",
        },
        footer: {
            copyrightText: [
                "Family Car Deals is a trading style of Leasing Options Limited and is authorised and regulated by the Financial Conduct Authority FRN 660061. Our BVRLA membership number is 1487. We act as a credit broker not a lender. We can introduce you to a limited number of lenders who may be able to offer you finance facilities for your purchase. We will only introduce you to these lenders. We may receive a commission payment from the finance provider if you decide to enter into an agreement with them. You may be able to obtain finance for your purchase from other lenders and you are encouraged to seek alternative quotations. Business customers may not be protected under the Consumer Credit Act 1974 or the rules of the Financial Conduct Authority. Leasing Options Limited trading as Family Car Deals - Registered in England and Wales. Company number: 02487254. Registered with the Information Commissioner’s Office as a Data Controller. Data Protection Registration number: Z7436776. Registered Office Address: Options House, Atkin Street, Worsley, Manchester, M28 3DG Telephone: 0161 660 9339. Email: info@familycardeals.co.uk"
            ]
        }
    }
}