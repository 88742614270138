import { AppConfig } from '../interfaces/AppConfig.interface';

export let matchMyMotorConfig: AppConfig = {
    defaults: {
        companyName: "Match My Motor",
    },
    theme: {
        images: {
            logoPath: "/affiliate/images/logos/match-my-motor.png",
            faviconPath: "/favicons/default"
        },
        colors: {
            primaryBg: "#3f2a56",
            primaryBgHover: "#2d1e3e",
            primaryText: "#ffffff",
            secondaryBg: "#45d8b5",
            secondaryBgHover: "#3cbd9e",
            secondaryText: "#ffffff",
        },
        poweredBy: {
            name: "Leasing Options",
            siteUrl: "https://www.leasingoptions.co.uk",
            logo: "/affiliate/images/powered-by.png"
        },
        footer: {
            copyrightText: [
                "Leasing Options Limited is authorised and regulated by the Financial Conduct Authority FRN 660061. Our BVRLA membership number is 1487. We act as a credit broker not a lender. We can introduce you to a limited number of lenders who may be able to offer you finance facilities for your purchase. We will only introduce you to these lenders. We may receive a commission payment from the finance provider if you decide to enter into an agreement with them. You may be able to obtain finance for your purchase from other lenders and you are encouraged to seek alternative quotations. Business customers may not be protected under the Consumer Credit Act 1974 or the rules of the Financial Conduct Authority."
            ]
        }
    }
}