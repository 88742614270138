import { AppConfig } from '../interfaces/AppConfig.interface';

export let autofinitiConfig: AppConfig = {
    defaults: {
        companyName: "Autofiniti",
    },
    theme: {
        images: {
            logoPath: "/affiliate/images/logos/autofinity.svg",
            faviconPath: "/favicons/default"
        },
        colors: {
            primaryBg: "#004DC1",
            primaryBgHover: "#013b92",
            primaryText: "#ffffff",
            secondaryBg: "#333333",
            secondaryBgHover: "#131313",
            secondaryText: "#ffffff",
        },
        poweredBy: {
            name: "Blue Light",
            logo: "/affiliate/images/logos/powered-by-bluelight.svg"
        },
        footer: {
            copyrightText: [
                "Autofiniti Leasing is a trading style of Leasing Options Limited. Authorised and regulated by the Financial Conduct Authority FRN 660061. Our BVRLA membership number is 1487. We act as a credit broker not a lender. We can introduce you to a limited number of lenders who may be able to offer you finance facilities for your purchase. We will only introduce you to these lenders. We may receive a commission payment from the finance provider if you decide to enter into an agreement with them. You may be able to obtain finance for your purchase from other lenders and you are encouraged to seek alternative quotations. If you would like to know how we handle complaints, please ask for a copy of our complaints handling process. You can also find information about referring a complaint to the Financial Ombudsman Service (FOS) at financial-ombudsman.org.uk"
            ]
        }
    }
}